import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { onGitHubOAuthClicked } from './utils';
import { Segment } from 'semantic-ui-react';
const LoginForm = () => {
  let navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      let status = localStorage.getItem('status');
      if (status) {
        status = JSON.parse(status);
        // showInfo('有状态');
        // github授权登录
        onGitHubOAuthClicked(status.github_client_id);
      }else{
        navigate('/login');
      }
    }, 300);
  }, []);

  return <Segment style={{ minHeight: '300px' }} className='flex-center'><div>正在授权中...</div></Segment>;
};

export default LoginForm;
