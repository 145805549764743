import React, { useContext, useEffect, useState } from 'react';
import { Loader, Segment } from 'semantic-ui-react';
import { useSearchParams,Link } from 'react-router-dom';
import { API, showSuccess, isAdmin,isLogin,getServerAddress } from '../helpers';
import { UserContext } from '../context/User';
import { ITEMS_PER_PAGE } from '../constants';

const GitHubOAuth = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [userState, userDispatch] = useContext(UserContext);
  const [tokens, setTokens] = useContext(UserContext);
  const [prompt, setPrompt] = useState('授权处理中...');
  const [processing, setProcessing] = useState(true);
  const [keyInfo, setKeyInfo] = useState('');
  const is_admin = isAdmin();
  const is_login = isLogin();
  const serverAddress = getServerAddress()

  const sendCode = async (code, state, count) => {
    setProcessing(true);
    const res = await API.get(`/api/oauth/github?code=${code}&state=${state}`);
    const { success, message, data } = res.data;
    if (success) {
      setPrompt('');
      if (message === 'bind') {
        // showSuccess('绑定成功！');
        setKeyInfo('绑定成功！');
      } else {
        userDispatch({ type: 'login', payload: data });
        localStorage.setItem('user', JSON.stringify(data));
        setKeyInfo('授权成功！');
        showToken();
      }
    } else {
      if (count === 0) {
        setPrompt(`授权失败，请清空该页面缓存后重新授权`); // 授权登录状态刷新页面（重新授权）会出现这个错误
        return;
      }
      count++;
      setPrompt(`出现错误，第 ${count} 次重试中...`);
      await new Promise((resolve) => setTimeout(resolve, count * 2000));
      await sendCode(code, state, count);
    }
    setProcessing(false);
  };

  // 加载令牌
  const loadTokens = async (startIdx) => {
    const res = await API.get(`/api/token/?p=${startIdx}`);
    const { success, message, data } = res.data;
    if (success) {
      if (startIdx === 0) {
        return data
      } else {
        let newTokens = [...tokens];
        newTokens.splice(startIdx * ITEMS_PER_PAGE, data.length, ...data);
        return newTokens
      }
    } else {
      return [];
    }
  };
  // 自动创建令牌
  async function handleCreateToken() {
    const params = {
      name: '默认令牌KEY',
      remain_quota: 2500000,
      expired_time: -1,
      unlimited_quota: false,
      model_limits_enabled: false,
      model_limits: '',
    };

    const res = await API.post(`/api/token/`, params);
    const { success, message } = res.data;
    return success;
  }

  // 显示令牌
  const showToken = async () => {
    let tokens = await loadTokens(0);
    
    if (tokens.length === 0) {
      const success = await handleCreateToken();
      if (success) {
        tokens = await loadTokens(0);
      }
    }
    if (tokens.length > 0) {
      const token = tokens[0];
      const keyInfo = (
        <div>
          <h3>授权成功！</h3>
          <div className='bo flex-y-center text-base' style={{marginBottom:-1}}><div className='px-3 py-1' style={{borderRight:'1px solid var(--border)',width:116,textAlign:'center'}}>API URL</div><div className='px-3 py-1'>{serverAddress}</div></div>
          <div className='bo flex-y-center mb-5 text-base'><div className='px-3 py-1' style={{borderRight:'1px solid var(--border)',width:116,textAlign:'center'}}>API KEY</div><div className='px-3 py-1'>sk-{token.key}</div></div>
          <p>请妥善保管，不要泄漏到公共场合或共享给他人使用。查看使用说明请 <a href='https://github.com/popjane/free_chatgpt_api?tab=readme-ov-file#%E5%B8%B8%E7%94%A8%E5%BA%94%E7%94%A8%E6%94%AF%E6%8C%81' target='_blank'>返回项目</a></p>
          <p>公益免费KEY只适用于个人使用，有高并发需求请支持我们的 <a href='https://api.gpt.ge' target='_blank'>付费项目</a>！</p>
        </div>
      );
      setPrompt('');
      setKeyInfo(keyInfo);
    }
  }

  useEffect(() => {
    let code = searchParams.get('code');
    let state = searchParams.get('state');

    // root角色绑定github账号成功后移除这里的&& !is_admin
    if(is_login){
      showToken()
    }else{
      sendCode(code, state, 0).then();
    }
  }, []);

  return (
    <Segment style={{ minHeight: '300px' }} className='flex-center'>
      {prompt ? (
        <div className='flex flex-col text-center'>
        <Loader size='large'>{prompt}</Loader>
        </div>
      ) : (
        <>
          <div>{keyInfo}</div>
        </>
      )}
    </Segment>
  );
};

export default GitHubOAuth;
