import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Grid, Header, Image, Segment } from 'semantic-ui-react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { UserContext } from '../context/User';
import {
  API,
  getLogo,
  showInfo,
  showError,
  showSuccess,
  showWarning,
} from '../helpers';
import { onGitHubOAuthClicked } from './utils';

const LoginForm = () => {
  const [inputs, setInputs] = useState({
    username: '',
    password: '',
    wechat_verification_code: '',
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [submitted, setSubmitted] = useState(false);
  const { username, password } = inputs;
  const [userState, userDispatch] = useContext(UserContext);
  let navigate = useNavigate();
  const [status, setStatus] = useState({});
  const logo = getLogo();

  useEffect(() => {
    setTimeout(() => {
      let status = localStorage.getItem('status');
      if (status) {
        // showInfo('有状态');
        status = JSON.parse(status);
        setStatus(status);
      }
    }, 200);
  }, []);

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  async function handleSubmit(e) {
    setSubmitted(true);
    if (username && password) {
      const res = await API.post(`/api/user/login`, {
        username,
        password,
      });
      const { success, message, data } = res.data;
      if (success) {
        userDispatch({ type: 'login', payload: data });
        localStorage.setItem('user', JSON.stringify(data));
        if (username === 'root' && password === '123456') {
          navigate('/user/edit');
          showSuccess('登录成功！');
          showWarning('请立刻修改默认密码！');
        } else {
          navigate('/setting');
          showSuccess('登录成功！');
        }
      } else {
        showError(message);
      }
    }
  }

  return (
    <Grid textAlign='center' style={{ marginTop: '48px' }}>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Segment>
          {status.github_oauth ? (
            <>
              <Button
                circular
                color='black'
                icon='github'
                onClick={() => onGitHubOAuthClicked(status.github_client_id)}
              />
              <p className='mt-5'>点击 Github图标 进行授权</p>
            </>
          ) : (
            <>
              {/* <Header as='h2' textAlign='center'>
                <Image src={logo} /> 授权登录
              </Header> */}
            </>
          )}
          {/* <Form size='large'>
            <Form.Input
              fluid
              icon='user'
              iconPosition='left'
              placeholder='用户名 / 邮箱地址'
              name='username'
              value={username}
              onChange={handleChange}
            />
            <Form.Input
              fluid
              icon='lock'
              iconPosition='left'
              placeholder='密码'
              name='password'
              type='password'
              value={password}
              onChange={handleChange}
            />
            <Button color='green' fluid size='large' onClick={handleSubmit}>
              登录
            </Button>
          </Form> */}
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

export default LoginForm;
