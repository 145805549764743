import React from 'react';
import { Segment } from 'semantic-ui-react';

const NotFound = () => (
  <Segment style={{ minHeight: '300px' }} className='flex-center flex-col'>
    <h3>请先授权</h3>
    <p>
      请前往{' '}
      <a href='https://github.com/popjane/free_chatgpt_api' title='free chatgpt api'>Github项目</a>{' '}
      中进行免费授权，或支持{' '}
      <a href='https://api.gpt.ge' title='chatgpt api'>
        付费项目
      </a>
      ！
    </p>
  </Segment>
);

export default NotFound;
